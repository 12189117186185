import React from 'react';

const CollectionContext = React.createContext({
  voteContract: null,
  proposals: [],
  myVote:0,
  totalVote:0,
  loadContract: () => {}
});

export default CollectionContext;