import { useState, useContext } from 'react';

import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';

const Proposals = () => {  

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  return(

    <main role="main" className="">
      <div className="content mr-auto ml-auto">
      {collectionCtx && collectionCtx.proposals && collectionCtx.proposals.length>0 && collectionCtx.myVote >0 &&
        <table className="table">
          <thead className="thead-dark">
          <tr>
          <th className="desktop" style={{width:"2%"}} scope="col">#</th>
          <th style={{width:"2%"}} scope="col">Active</th>
          <th className="desktop" style={{width:"10%"}} scope="col">Creator</th>
          <th style={{width:"20%"}} scope="col">Name</th>
          <th className="desktop" scope="col">Description</th>
          <th style={{width:"10%"}} scope="col">Ending</th>
        </tr>
  </thead>
  <tbody>
  {
      collectionCtx.proposals.map((item,name) => 
      {
        return (  
        <tr style={{cursor:"pointer"}} onClick={e=>{window.location.href=`/#/proposals/${item.id}`}} key={item.id}>
          <th className="desktop" scope="row">{item.id}</th>
          <th scope="row">{item.active?"yes":"no"}</th>
          <th className="desktop" scope="row">{item.creator}</th>
          <th scope="row">{item.name}</th>
          <th className="desktop" scope="row">{item.text.substr(0,100)}...</th>
          <th scope="row">{item.ending}</th>
        </tr>)
        }
        )
      }
  </tbody>
</table>
}
{collectionCtx && collectionCtx.proposals && collectionCtx.proposals.length>0 && collectionCtx.myVote == 0 &&
  <div> You need to be VIPWarz NFTs owner to view this page</div>
}

    </div>
        </main>

  );
};

export default Proposals;