import { useState, useContext ,useEffect} from 'react';
import {  useParams} from "react-router-dom";
import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import web3 from '../../connection/web3';

import ERC20 from '../../abis/erc20.json';
import VVTStake from '../../abis/VVTStake.json';
const BigNumber = require("bignumber.js");

const stakeAddress1 = "0x95Ec147D81EB6a39Ec1b7702b6C73cDC117FDcE6";
const stakeAddress3 = "0x09aaCA1C45f71EEFa5552b216e665ca59c4E6195";
const stakeAddress6 = "0xc9B5573e6c0Fd1D816064AFf67D8B4B9882563Af";

 let stakeContract1 = null;
 let stakeContract3 = null;
 let stakeContract6 = null;
const Staking = () => {  

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);

  const [transactionActive, setTransactionActive] = useState(false);

  const [allowed1,setAllowed1] = useState(0);
  const [allowed3,setAllowed3] = useState(0);
  const [allowed6,setAllowed6] = useState(0);

  const [amount1,setAmount1] = useState(0);
  const [amount3,setAmount3] = useState(0);
  const [amount6,setAmount6] = useState(0);

  const [apr1, setApr1] = useState(0);
  const [apr3, setApr3] = useState(0);
  const [apr6, setApr6] = useState(0);

  const [total1, setTotal1] = useState(0);
  const [total3, setTotal3] = useState(0);
  const [total6, setTotal6] = useState(0);

  const [myStake1, setMyStake1] = useState(0);
  const [myStake3, setMyStake3] = useState(0);
  const [myStake6, setMyStake6] = useState(0);

  const [myUnlock1, setMyUnlock1] = useState("");
  const [myUnlock3, setMyUnlock3] = useState("");
  const [myUnlock6, setMyUnlock6] = useState("");

  const [harvest1, setHarvest1] = useState(0);
  const [harvest3, setHarvest3] = useState(0);
  const [harvest6, setHarvest6] = useState(0);

useEffect(() => {
    const interval = setInterval(async() => {
        if(!web3Ctx ||!web3Ctx.account || !stakeContract6){
        return;
    }
    let _harvest1 = parseInt(await stakeContract1.methods.pendingReward(web3Ctx.account ).call())/10**18;
    setHarvest1(_harvest1.toFixed(2));
    let _harvest3 = parseInt(await stakeContract3.methods.pendingReward(web3Ctx.account ).call())/10**18;
    setHarvest3(_harvest3.toFixed(2));
    let _harvest6 = parseInt(await stakeContract6.methods.pendingReward(web3Ctx.account ).call())/10**18;
    setHarvest6(_harvest6.toFixed(2));
  }, 30000);
  return () => clearInterval(interval);
}, [stakeContract6]);
   useEffect(() => {
    if(!web3 || !web3.eth){
      return null;
    }
     if(!stakeContract1){
       stakeContract1 = new web3.eth.Contract(VVTStake.abi, stakeAddress1);
       stakeContract3 = new web3.eth.Contract(VVTStake.abi, stakeAddress3);
       stakeContract6 = new web3.eth.Contract(VVTStake.abi, stakeAddress6);

       loadInfo();
       stakeContract1.events.Claim().on('data', (event) => {
        loadInfo();
        });
       stakeContract3.events.Claim().on('data', (event) => {
        loadInfo();
        });
       stakeContract6.events.Claim().on('data', (event) => {
        loadInfo();
        });
     }
  }, []);
      useEffect(() => {
            if(!web3 || !web3.eth){
      return null;
    }
       stakeContract1 = new web3.eth.Contract(VVTStake.abi, stakeAddress1);
       stakeContract3 = new web3.eth.Contract(VVTStake.abi, stakeAddress3);
       stakeContract6 = new web3.eth.Contract(VVTStake.abi, stakeAddress6);
       stakeContract1.events.Claim().on('data', (event) => {
        loadInfo();
        });
       stakeContract3.events.Claim().on('data', (event) => {
        loadInfo();
        });
       stakeContract6.events.Claim().on('data', (event) => {
        loadInfo();
        });
       loadInfo();

  }, [web3Ctx, web3Ctx.account]);
  const loadInfo = async() =>{
    let _total1 = parseInt(await stakeContract1.methods.totalStaked().call())/10**18;
    let _perYear1 = parseInt(await stakeContract1.methods.TokensPerYear().call())/10**18;
    let apr1 = _perYear1/(_total1/100);
    setApr1(apr1.toFixed(2));
    setTotal1(_total1.toFixed(2));

    let _total3 = parseInt(await stakeContract3.methods.totalStaked().call())/10**18;
    let _perYear3 = parseInt(await stakeContract3.methods.TokensPerYear().call())/10**18;
    let apr3 = _perYear3/(_total3/100);
    setApr3(apr3.toFixed(2));
    setTotal3(_total3.toFixed(2));

    let _total6 = parseInt(await stakeContract6.methods.totalStaked().call())/10**18;
    let _perYear6 = parseInt(await stakeContract6.methods.TokensPerYear().call())/10**18;
    let apr6 = _perYear6/(_total6/100);
    setApr6(apr6.toFixed(2));
    setTotal6(_total6.toFixed(2));
if(web3Ctx && web3Ctx.account){
    let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
    let user = await stakeContract1.methods.userInfo(web3Ctx.account).call();
    setMyStake1(user.amount/10**18);
    setMyUnlock1(new Date(parseInt(user.unlockTime)*1000).toLocaleDateString());
    
    user = await stakeContract3.methods.userInfo(web3Ctx.account).call();
    setMyStake3(user.amount/10**18);
    setMyUnlock3(new Date(parseInt(user.unlockTime)*1000).toLocaleDateString());
    
     user = await stakeContract6.methods.userInfo(web3Ctx.account).call();
    setMyStake6(user.amount/10**18);
    setMyUnlock6(new Date(parseInt(user.unlockTime)*1000).toLocaleDateString());
    
    
    
    setAllowed1((await VVTcontract.methods.allowance(web3Ctx.account,stakeContract1._address).call())/10**18);
    setAllowed3((await VVTcontract.methods.allowance(web3Ctx.account,stakeContract3._address).call())/10**18);
    setAllowed6((await VVTcontract.methods.allowance(web3Ctx.account,stakeContract6._address).call())/10**18);

    let _harvest1 = parseInt(await stakeContract1.methods.pendingReward(web3Ctx.account ).call())/10**18;
    setHarvest1(_harvest1.toFixed(2));
    let _harvest3 = parseInt(await stakeContract3.methods.pendingReward(web3Ctx.account ).call())/10**18;
    setHarvest3(_harvest3.toFixed(2));
    let _harvest6 = parseInt(await stakeContract6.methods.pendingReward(web3Ctx.account ).call())/10**18;
    setHarvest6(_harvest6.toFixed(2));
  }
  }
       const switchNetwork = async(networkId) => {
      if(!web3Ctx){
        return;
      }
    if(!web3 || !web3.eth){
      return null;
    }
          web3Ctx.switchToCallisto(web3);
  };
  const connectWallet = async() => {
     if(!web3Ctx){
        return;
      }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }
    // Load accounts
    if(!web3Ctx.loadAccount){
        return;
      }
    web3Ctx.loadAccount(web3);
  };

 const handleAdd = async(contractType) => {
    if(!web3Ctx.account){
      connectWallet();
      return;
    }
    console.log(web3Ctx.networkId );
    let contract = stakeContract1;
    let amount = amount1;
    if(contractType == 3){
      contract = stakeContract3;
      amount = amount3;
    }
    if(contractType == 6){
      contract = stakeContract6;
      amount = amount6;
    }
    /*if(web3Ctx.networkId != 820){
      switchNetwork()
      return;
    }*/
      let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
      if(VVTcontract){
        let allowed = (await VVTcontract.methods.allowance(web3Ctx.account,contract._address).call())/10**18;
        console.log(allowed,parseFloat(amount));
        if(allowed >=  parseFloat(amount)){
          console.log(amount);
          contract.methods.AddTokens(web3.utils.toWei(amount+"")).send({ from: web3Ctx.account})
          .on('transactionHash', (hash) => {setTransactionActive(true);})
          .on('receipt', (hash) => {
            setTransactionActive(false);
            loadInfo();
          })
          .on('error', (error) => {setTransactionActive(false); window.alert('Something went wrong when pushing to the blockchain'); });   
        }else{
          VVTcontract.methods.approve(contract._address,BigNumber(10**18).multipliedBy(10**18).toFixed()).send({ from: web3Ctx.account})
             .on('transactionHash', (hash) => {
               setTransactionActive(true);
             })
             .on('receipt', (hash) => {
               setTransactionActive(false);
               loadInfo();
             })
             .on('error', (error) => {
              setTransactionActive(false);
              window.alert('Something went wrong when pushing to the blockchain');
            });
          }
        }
      }     
  

  const handleHarvest1 = async() => {
    if(web3Ctx && web3Ctx.account && harvest1>0){
      stakeContract1.methods.claim().send({ from: web3Ctx.account});
    }
  }
    const handleHarvest3 = async() => {
    if(web3Ctx && web3Ctx.account && harvest3>0){
      stakeContract3.methods.claim().send({ from: web3Ctx.account});
    }
  }
    const handleHarvest6 = async() => {
    if(web3Ctx && web3Ctx.account && harvest6>0){
      stakeContract6.methods.claim().send({ from: web3Ctx.account});
    }
  }

    const handleMax1 = async(e) => {
       e.preventDefault();
      if(web3Ctx && web3Ctx.account){
        let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
        setAmount1((Math.floor((await VVTcontract.methods.balanceOf(web3Ctx.account).call())/10**18*100)/100).toFixed(2));
      }
      return false;
    };
        const handleMax3 = async(e) => {
       e.preventDefault();
      if(web3Ctx && web3Ctx.account){
        let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
        setAmount3((Math.floor((await VVTcontract.methods.balanceOf(web3Ctx.account).call())/10**18*100)/100).toFixed(2));
      }
      return false;
    };
  
      const handleMax6 = async(e) => {
       e.preventDefault();
      if(web3Ctx && web3Ctx.account){
        let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
        setAmount6((Math.floor((await VVTcontract.methods.balanceOf(web3Ctx.account).call())/10**18*100)/100).toFixed(2));
      }
      return false;
    };
  
  
  return(
   <main role="main">
    {collectionCtx &&
      <div className="content row mr-auto ml-auto">
        <div className="row">

          <div className="col-4 infoBox2">
          <h2 className="stakeHeader"> VVT Staking</h2>
          <h4 className="stakeInfo">Lock duration: 1 Month</h4>
          <h4 className="stakeInfo">Estimated APR: {apr1}%</h4>
          <h4 className="stakeInfo">Total Staked: {total1} VVT</h4>
          <div style={{marginTop: "20px"}} className="stakeInfo">
          <h4  style={{fontWeight: "800"}}>My Stake: {myStake1} VVT</h4>
          <h4 >Unlocking: {myUnlock1}</h4>
          <button onClick={handleHarvest1} className="harvestStake">Harvest {harvest1} VVT</button>
          </div>
          <div style={{marginTop: "30px"}} className="stakeInfo">
          <h4  style={{fontWeight: "800"}}>Add VVT</h4>
            <input value={amount1} onChange={e=>{setAmount1(parseFloat(e.target.value))}} className="inputStake" type="number"/>
            <a onClick={e=>handleMax1(e)} href="#">MAX</a>
              </div>
            <div style={{marginTop: "15px",marginBottom: "20px"}} className="stakeInfo">
            <button onClick={e=>handleAdd(1)} className="AddStake">{allowed1>amount1?"Stake VVT":"Enable VVT"}</button> </div>
            <span>You can add more tokens while your staking active. This resets lock period!</span>
         </div>
      
          <div className="col-4 infoBox2">
          <h2 className="stakeHeader"> VVT Staking</h2>
          <h4 className="stakeInfo">Lock duration: 3 Months</h4>
          <h4 className="stakeInfo">Estimated APR: {apr3}%</h4>
          <h4 className="stakeInfo">Total Staked: {total3} VVT</h4>
          <div style={{marginTop: "20px"}} className="stakeInfo">
          <h4  style={{fontWeight: "800"}}>My Stake: {myStake3} VVT</h4>
          <h4 >Unlocking: {myUnlock3}</h4>
          <button onClick={handleHarvest3}   className="harvestStake">Harvest {harvest3} VVT</button>
          </div>
          <div style={{marginTop: "30px"}} className="stakeInfo">
          <h4  style={{fontWeight: "800"}}>Add VVT</h4>
            <input value={amount3} onChange={e=>{setAmount3(parseFloat(e.target.value))}} className="inputStake" type="number"/>
            <a href="#" onClick={e=>handleMax3(e)}>MAX</a>
              </div>
            <div style={{marginTop: "15px",marginBottom: "20px"}} className="stakeInfo">
            <button onClick={e=>handleAdd(3)}  className="AddStake">{allowed3>amount3?"Stake VVT":"Enable VVT"}</button> </div>
            <span>You can add more tokens while your staking active. This resets lock period!</span>
         </div>

          <div className="col-4 infoBox2">
          <h2 className="stakeHeader"> VVT Staking</h2>
          <h4 className="stakeInfo">Lock duration: 6 Months</h4>
          <h4 className="stakeInfo">Estimated APR: {apr6}%</h4>
          <h4 className="stakeInfo">Total Staked: {total6} VVT</h4>
          <div style={{marginTop: "20px"}} className="stakeInfo">
          <h4  style={{fontWeight: "800"}}>My Stake: {myStake6} VVT</h4>
          <h4 >Unlocking: {myUnlock6}</h4>
          <button onClick={handleHarvest6}  className="harvestStake">Harvest {harvest6} VVT</button>
          </div>
          <div style={{marginTop: "30px"}} className="stakeInfo">
          <h4  style={{fontWeight: "800"}}>Add VVT</h4>
            <input value={amount6} onChange={e=>{setAmount6(parseFloat(e.target.value))}} className="inputStake" type="number"/>
            <a onClick={e=>handleMax6(e)}  href="#">MAX</a>
              </div>
            <div style={{marginTop: "15px",marginBottom: "20px"}} className="stakeInfo">
            <button onClick={e=>handleAdd(6)}   className="AddStake">{allowed6>amount6?"Stake VVT":"Enable VVT"}</button> </div>
            <span>You can add more tokens while your staking active. This resets lock period!</span>
         </div>

        </div>
      </div>
    }</main>

  );
};

export default Staking;