import React, { useContext, useEffect } from 'react';
import {HashRouter as Router,Routes,Route, useLocation} from 'react-router-dom';

import web3 from './connection/web3';

import Proposals from './components/Content/Proposals';
import AddProposal from './components/Content/AddProposal';
import ViewProposal from './components/Content/ViewProposal';
import Staking from './components/Content/Staking';


import Web3Context from './store/web3-context';
import CollectionContext from './store/collection-context';

import './assets/boxicons-2.0.7/css/boxicons.min.css'
import './assets/css/grid.css'
import './assets/css/theme.css'
import './assets/css/index.css'

import Sidebar from 'components/sidebar/Sidebar'
import TopNav from 'components/topnav/TopNav'


const App = () => {

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);

 
  useEffect(() => {
    // Check if the user has Metamask active
    if(!web3) {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
      return;
    }
    
    // Function to fetch all the blockchain data
    const loadBlockchainData = async() => {
      // Request accounts acccess if needed
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });  
      } catch(error) {
        console.error(error);
      }
      
      let account = await web3Ctx.loadAccount(web3);
      await web3Ctx.loadNetworkId(web3);
      if(account){
        collectionCtx.loadContract(web3,account);
      }
      
      // Metamask Event Subscription - Account changed
      window.ethereum.on('accountsChanged', async(accounts) => {
        let account = await web3Ctx.loadAccount(web3);
        collectionCtx.loadContract(web3,account);
      });

      // Metamask Event Subscription - Network changed
      window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
      });
    };
    
    loadBlockchainData();
  }, []);

  
  return(
      <Router>
                <div  className={`layout theme-mode-light`}>
                    <Sidebar />
                    <div className="layout__content">
                        <TopNav/>
                        <div className="layout__content-main">
                      <Routes>                  
                <Route path="/" element={<Proposals />}/>
                <Route path="/staking" element={<Staking />}/>
                <Route path="/AddProposal" element={<AddProposal />}/>
                <Route path="/proposals/:proposalId" element={<ViewProposal/>}/>
              </Routes>
                        </div>
                    </div>
                </div>
      </Router>
  );
};

export default App;