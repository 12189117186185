import { useState, useContext } from 'react';
import {  useParams} from "react-router-dom";
import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import web3 from '../../connection/web3';

import ERC20 from '../../abis/erc20.json';

const ViewProposal = () => {  

  const [name, setName] = useState('');
  const [text, setText] = useState('');

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const { proposalId } = useParams();

    const VoteAccept = async() => {
    let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
    VVTcontract.methods.approve(collectionCtx.voteContract.options.address,web3.utils.toWei("100")).send({ from: web3Ctx.account }).on('receipt', (hash) => {
    collectionCtx.voteContract.methods.VoteAccept(proposalId).send({ from: web3Ctx.account }).on('transactionHash',()=>{})
          .on('receipt', (hash) => {
            alert("Your voted added");
          })
          .on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
          }); 
        }).on('error', (error) => {
          window.alert('Something went wrong when pushing to the blockchain');
        }) 
    };
    const VoteDecline = async() => {
    let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
    VVTcontract.methods.approve(collectionCtx.voteContract.options.address,web3.utils.toWei("100")).send({ from: web3Ctx.account }).on('receipt', (hash) => {
    collectionCtx.voteContract.methods.VoteDecline(proposalId).send({ from: web3Ctx.account }).on('transactionHash',()=>{})
          .on('receipt', (hash) => {
            alert("Your voted added");
          })
          .on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
          }); 
        }).on('error', (error) => {
          window.alert('Something went wrong when pushing to the blockchain');
        }) 
    };

        const VoteVeto = async() => {
    let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
    VVTcontract.methods.approve(collectionCtx.voteContract.options.address,web3.utils.toWei("100")).send({ from: web3Ctx.account }).on('receipt', (hash) => {
    collectionCtx.voteContract.methods.VoteVeto(proposalId).send({ from: web3Ctx.account }).on('transactionHash',()=>{})
          .on('receipt', (hash) => {
            alert("Your voted added");
          })
          .on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
          }); 
        }).on('error', (error) => {
          window.alert('Something went wrong when pushing to the blockchain');
        }) 
    };
  
  return(
   <main role="main">
    {collectionCtx && collectionCtx.proposals && collectionCtx.proposals.length>0 && collectionCtx.myVote >0 &&
      <div className="content row mr-auto ml-auto">
        <div className="col-8">
          <div className="row " >
            <h1>{collectionCtx.proposals[proposalId].name}</h1>
            <p style={{whiteSpace: "pre-wrap", width:"95%"}} className="">{collectionCtx.proposals[proposalId].text}</p>
          </div>
          {collectionCtx.proposals[proposalId].voted == 0 &&<div style={{marginTop: "50px",marginBottom: "20px"}} className="row justify-content-center">
            <div className="col-4"><button onClick={VoteAccept} style={{borderColor:"#00d9a6",backgroundColor:"#00d9a6"}} className='btn btn-lg btn-success text-white btn-block btnVote'>Accept</button></div>
            <div className="col-4"><button onClick={VoteDecline} style={{borderColor:"#904FFF" ,backgroundColor:"#904FFF"}} className='btn btn-lg btn-danger text-white btn-block btnVote'>Decline</button></div>
            <div className="col-4"><button onClick={VoteVeto} style={{borderColor:"#eb006e" ,backgroundColor:"#eb006e"}} className='btn btn-lg btn-danger text-white btn-block btnVote'>VETO</button></div>
          </div>}
        </div>
        <div className="col-3">
          <div className="row infoBox">
          <h4>My Vote Power:{(collectionCtx.myVote/(collectionCtx.totalVote/100)).toFixed(2)}%</h4>
          <h4>Voted Accept: {(collectionCtx.proposals[proposalId].accept/(collectionCtx.totalVote/100)).toFixed(2)}%</h4>
          <h4>Voted Decline: {(collectionCtx.proposals[proposalId].decline/(collectionCtx.totalVote/100)).toFixed(2)}%</h4>
          <h4>Voted Veto: {(collectionCtx.proposals[proposalId].veto/(collectionCtx.totalVote/100)).toFixed(2)}%</h4>
          
          <h4>Active: {collectionCtx.proposals[proposalId].active?"yes":"no"}</h4>
          <h4>Ending: {collectionCtx.proposals[proposalId].ending}</h4>
          <h4>I vote for: {collectionCtx.proposals[proposalId].voted==0?"":collectionCtx.proposals[proposalId].voted==1?"Accept":collectionCtx.proposals[proposalId].voted==2?"Decline":"Veto!"}</h4>
          </div>
          <div style={{marginTop: "30px"}} className="row infoBox text-center">
          Each user can vote only once and user vote power calculated based on NFT cards in game.
          Vote costs 100 VVT which will be burned
          </div>
        </div>
      </div>
    }</main>

  );
};

export default ViewProposal;