import React from 'react'
import { useContext } from 'react';
import './topnav.css'
import { Link } from 'react-router-dom'
import Web3Context from '../../store/web3-context';
import web3 from '../../connection/web3';

const renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
        <i className={item.icon}></i>
        <span>{item.content}</span>
    </div>
)


const renderUserMenu =(item, index) => (
    <Link to='/' key={index}>
        <div className="notification-item">
            <i className={item.icon}></i>
            <span>{item.content}</span>
        </div>
    </Link>
)

const Topnav = () => {
    const web3Ctx = useContext(Web3Context);
    const connectWalletHandler = async() => {
    try {
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      console.error(error);
    }

    // Load accounts
    web3Ctx.loadAccount(web3);
  };
    return (
        <div className='topnav'>
        <Link className="mobile" style={{marginRight:"10px"}} to="/">Proposals</Link>
        <Link className="mobile"  to="/Staking">Staking</Link>
            <div className="topnav__right">
                <div className="topnav__right-item">
                {web3Ctx.account && 
                    <a>{web3Ctx.account.substr(0,6)}...{web3Ctx.account.substr(web3Ctx.account.length - 4)}</a>
                }
                  {!web3Ctx.account && 
            <button type="button" 
              className="btn btn-info text-white" 
              onClick={connectWalletHandler} 
            > 
              Connect your wallet
            </button>}
                    
                </div>
                <div className="topnav__right-item">
               
                </div>
            </div>
        </div>
    )
}

export default Topnav
