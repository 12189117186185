import { useState, useContext } from 'react';

import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import web3 from '../../connection/web3';

import ERC20 from '../../abis/erc20.json';

const AddProposal = () => {  

  const [name, setName] = useState('');
  const [text, setText] = useState('');

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);

    const AddProposal = async() => {
    let VVTcontract = new web3.eth.Contract(ERC20.abi, "0x9f9b6DD3DEDB4D2e6C679bCb8782f546400e9A53");//vvt
    VVTcontract.methods.approve(collectionCtx.voteContract.options.address,web3.utils.toWei("50000")).send({ from: web3Ctx.account }).on('receipt', (hash) => {
      console.log(name,text);
      collectionCtx.voteContract.methods.addProposal(name, text).send({ from: web3Ctx.account }).on('transactionHash',()=>{})
          .on('receipt', (hash) => {
            alert("Proposal added");
          })
          .on('error', (error) => {
            window.alert('Something went wrong when pushing to the blockchain');
          }); 
        }).on('error', (error) => {
          window.alert('Something went wrong when pushing to the blockchain');
        }) 
    };
  
  return(

   <main role="main" className="col-5 justify-content-center text-center">
          <div className="content mr-auto ml-auto">
    <div>

      <div className="row justify-content-center" >
      <h1>Add new proposal</h1>
          <input type='text' className={`mb-1`} placeholder="Proposal title" val={name} onChange={e=>{setName(e.target.value);}}/><br/>
          <textarea className={` mb-1`} val={text} onChange={e=>{setText(e.target.value);}}/><br/>
      </div>
      <button onClick={AddProposal} className='btn btn-lg btn-info text-white btn-block'>Add proposal</button>
    </div>
              </div>
        </main>

  );
};

export default AddProposal;