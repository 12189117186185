import { useReducer } from 'react';
import CollectionContext from './collection-context';
import VIPVote from 'abis/VIPVote.json';

const voteAddress = "0x9F5efb978c1f5A0D3F2c3C2472D766Ec808E68D5";


const defaultCollectionState = {
  voteContract: null,
  myVote: 0,
  totalVote: 0,
  proposals: [],
};

const collectionReducer = (state, action) => {
  let _state = Object.assign({}, state);
  if(action.type === 'LOADCONTRACT') {
    _state.voteContract = action.voteContract;
    return _state;
  }
  if(action.type === 'LOADPROPOSALS') {
    _state.proposals = action.proposals;
    return _state;
  }
  if(action.type === 'LOADVOTE') {
    _state.myVote = action.myVote;
    _state.totalVote = action.totalVote;
    return _state;
  }
  
  return defaultCollectionState;
};

const CollectionProvider = props => {
  const [CollectionState, dispatchCollectionAction] = useReducer(collectionReducer, defaultCollectionState);
  let voteContract = null;
  let web3 = null;

  async function loadContractHandler(_web3,account){
    if(!_web3 || !_web3.eth){
      return null;
    }
    web3 = _web3;
    voteContract = new web3.eth.Contract(VIPVote.abi, voteAddress);
    
    dispatchCollectionAction({type: 'LOADCONTRACT', voteContract: voteContract}); 
    if(!voteContract){
      return;
    }

    const proposals = await voteContract.methods.getProposals().call();
  //  console.log(proposals);
    let data =[];
    for(var i=0;i<proposals.length;i++){
      let voted = parseInt(await voteContract.methods.userVoted(account,i).call());
      data[i] ={id:i,name:proposals[i].name, text:proposals[i].text, creator:`${proposals[i].creator.substr(0,6)}...${proposals[i].creator.substr(proposals[i].creator.length - 4)}`,
        ending:new Date(parseInt(proposals[i].endTime)*1000).toLocaleDateString(),active:proposals[i].active,
        accept:parseInt(proposals[i].voteAccept),decline:parseInt(proposals[i].voteDecline),veto:parseInt(proposals[i].voteVeto),voted:voted};
    }
    console.log(data);
    dispatchCollectionAction({type: 'LOADPROPOSALS', proposals: data});
    web3.eth.getAccounts().then( async(accounts)=>{
        if(accounts && accounts[0]){
          let totalVote = await voteContract.methods.calcTotalPower().call();
          let myVote = await voteContract.methods.calcPower(accounts[0]).call();
          console.log("power",myVote,totalVote)
          dispatchCollectionAction({type: 'LOADVOTE', myVote: myVote,totalVote:totalVote});
         }
    });
    voteContract.events.Voted().on('data', (event) => {
      web3.eth.getAccounts().then( (accounts)=>{
        if(accounts && accounts[0]){
          loadContractHandler(web3,accounts[0]);
        }
      });
    });
  };

  const collectionContext = {
    voteContract: CollectionState.voteContract,
    proposals: CollectionState.proposals,
    myVote: CollectionState.myVote,
    totalVote: CollectionState.totalVote,
    loadContract: loadContractHandler
  };
  
  return (
    <CollectionContext.Provider value={collectionContext}>
      {props.children}
    </CollectionContext.Provider>
  );
};

export default CollectionProvider;